import Canvas from 'app/components/canvas'
import Sponsors from './components/sponsors'
import logo from '../images/logo.png' 
function App() {
  return (
    <div className="App">
      <div className="header-info">
        <a href="https://syntheseyes.de"><img src={logo}/></a>
        <p className="project-source">
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:contact@syntheseyes.de"
          >
            contact[at]syntheseyes[dot]de
          </a>
        </p>
      <Sponsors/>
      </div>
      <Canvas />

    </div>
  )
}

export default App