const useColor = () => {
  const randomInteger = () => Math.floor(Math.random() * 180)

  const generateColor = () => {
    return {
      frontWave: `rgba(${36}, ${78}, ${98}, 0.88)`,
      backWave: `rgba(${95}, ${164}, ${74}, 0.88)`,
      thirdWave: `rgba(${133}, ${176}, ${23}, 0.88)`,
      backgroundColor: `rgba(${0}, ${0}, ${0}, 0.88)`
    }
  }

  return {
    generateColor,
  }
}

export default useColor
