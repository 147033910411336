import { useRef, FC, useEffect, useState } from 'react'

import camp from '../../images/sponsors/camp.jpeg' 
import jhu from '../../images/sponsors/jhu.png' 
import maps from '../../images/sponsors/maps.png' 
import mri from '../../images/sponsors/mri.png' 
import tum from '../../images/sponsors/tum.png' 
import unter from '../../images/sponsors/unternehmentum.jpeg' 
import aimunich from '../../images/sponsors/ai-munich.png' 

const Sponsors: FC = () => {
  return (
      <div className="img-container">
        <a href="https://tum.de"><img className="sponsor" src={tum}/></a>
        <a href="https://www.cs.cit.tum.de/camp/start/"><img className="sponsor" src={camp}/></a>
        <a href="https://www.mri.tum.de/"><img className="sponsor" src={mri}/></a>
        <a href="https://engineering.jhu.edu/"><img className="sponsor" src={jhu}/></a>
        <a href="https://www.unternehmertum.de/"><img className="sponsor" src={unter}/></a>
        <a href="https://www.must-munich.com/aimunich/"><img className="sponsor-correct" src={aimunich}/></a>
      </div>
  )
}

export default Sponsors
